<template>
  <div id="home">
    <el-container>
      <el-header>
        <div class="left">
          <i class="el-icon-s-fold" @click="isCollapse = true" v-show="isCollapse == false"></i>
          <i class="el-icon-s-unfold" @click="isCollapse = false" v-show="isCollapse == true"></i>
          迪倍特运维管理云平台
          <!-- 乌审旗鑫浩泰自来水有限公司 - 运维管理云平台 -->
        </div>
        <div class="right">
          <span class="qrcode-wrap">
            <el-popover
              placement="bottom"
              trigger="hover">
              <div class="qrcode" ref="qrCodeUrl"></div>
              <el-button type="text" slot="reference" style="color:#FFF;font-size:15px;">下载APP</el-button>
            </el-popover>
          </span>
          <span @click="messageNotice">
            <span class="right-notice">
              <i class="iconfont icon-tongzhi" style="margin-right:5px;font-size: 18px;"></i>
              <el-badge :value="noticeNum" :max="99" :style="noticeNum>99?'right: -25px;':'right: -15px;'" class="right-badge" v-show="noticeNum!=0"></el-badge>
            </span>
          </span>
          <span class="right-help">
            <i class="iconfont icon-bangzhu" style="margin-right:5px;font-size: 17px;"></i>
            <span>帮助</span>
          </span>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <i class="el-icon-user" style="font-size: 16px;"></i> {{ roleId==0 ? '超级管理员' : name }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item><div @click="loginOut" style="width: 60px;">退出</div></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside>
          <el-menu 
            class="el-menu-vertical-demo"
            background-color="#324157"
            text-color="#fff"
            @open="handleOpen" 
            @close="handleClose"
            @select="handleSelect"
            :router="true"
            unique-opened
            :default-active="active"
            :collapse="isCollapse">
            <!-- 超级管理员 -->
            <div v-if="roleId == 0">
              <el-menu-item index="/dashboard">
                <i class="iconfont icon-shouye"></i>
                <span slot="title">首页</span>
              </el-menu-item>
              <!-- 模块重新调整 -->
              <template v-if="roleId != 2">
                <!-- 数字孪生水务系统 -->
                <el-submenu index="23">
                  <template slot="title">
                    <i class="iconfont icon-beijianleixing-"></i>
                    <span slot="title">数字孪生管理</span>
                  </template>
                  <el-menu-item index="/iotTwinsManage">孪生体管理</el-menu-item>
                  <el-menu-item index="/iotClassifiTwins">孪生体分类</el-menu-item>
                </el-submenu>

                <!-- 物联网设备管理与数据采集系统 -->
                <el-submenu index="21">
                  <template slot="title">
                    <i class="iconfont icon-ic_IOT"></i>
                    <span slot="title">物联网设备管理</span>
                  </template>
                  <el-menu-item index="/iotSupplier">供应商管理</el-menu-item>
                  <el-menu-item index="/iotSupplierDevice">供应商品牌</el-menu-item>
                  <el-menu-item index="/iotProduct">设备类型</el-menu-item>
                  <el-menu-item index="/iotReportFirst">数据采集</el-menu-item>
                  <el-menu-item index="/iotEquipment">设备管理</el-menu-item>
                </el-submenu>

                <!-- 城乡供水管网监测系统 -->
                <el-submenu index="7">
                  <template slot="title">
                    <i class="iconfont icon-guanwang"></i>
                    <span slot="title">管网监测</span>
                  </template>
                  <el-menu-item index="/overviewPipelineNetwork">管网概况</el-menu-item>
                  <el-menu-item index="/pipeline">管线列表</el-menu-item>
                  <el-menu-item index="/pipelineDisplay">管网分布</el-menu-item>
                  <el-menu-item index="/pipelineCycle">管网巡检</el-menu-item>
                </el-submenu>

                <!-- 设备智能化远程控制系统 -->
                <el-submenu index="22">
                  <template slot="title">
                    <i class="iconfont icon-peizhi"></i>
                    <span slot="title">远程控制</span>
                  </template>
                  <el-menu-item index="/iotConfigManage">组态管理</el-menu-item>
                  <el-menu-item index="/iotConfigExhib">组态展示</el-menu-item>
                  <el-menu-item index="/iotResource">组态资源</el-menu-item>
                  <el-menu-item index="/iotConfigRecord">组态记录</el-menu-item>
                </el-submenu>

                <!-- 人员巡点检智能运维系统 -->
                <el-submenu index="1">
                  <template slot="title">
                    <i class="iconfont icon-xunjian"></i>
                    <span slot="title">智能运维</span>
                  </template>
                  <el-menu-item v-if="roleId != 2" index="/cycle">巡检计划</el-menu-item>
                  <el-menu-item index="/dynamiccheck">巡检任务</el-menu-item>
                  <el-menu-item index="/recordCycle">巡检记录</el-menu-item>
                  <el-menu-item index="/cycleRecordExport">巡检记录导出</el-menu-item>
                  <el-menu-item index="/cycleUndetectedReset">巡检漏检重置</el-menu-item>
                  <el-menu-item v-if="roleId != 2" index="/check">点检计划</el-menu-item>
                  <el-menu-item index="/dynamicpatrol">点检任务</el-menu-item>
                  <el-menu-item index="/recordPoint">点检记录</el-menu-item>
                  <el-menu-item index="/checkRecordExport">点检记录导出</el-menu-item>
                  <el-menu-item index="/checkUndetectedReset">点检漏检重置</el-menu-item>
                  <el-menu-item index="/overhaulTask">检修计划</el-menu-item>
                  <el-menu-item index="/overhaulDynamic">检修任务</el-menu-item>
                  <el-menu-item index="/overhaulRecord">检修记录</el-menu-item>
                  <el-menu-item index="/lubricationTask">润滑计划</el-menu-item>
                  <el-menu-item index="/lubricationDynamic">润滑任务</el-menu-item>
                  <el-menu-item index="/lubricationRecord">润滑记录</el-menu-item>
                  <el-menu-item index="/lubricaOilManage">润滑油管理</el-menu-item>
                  <el-menu-item index="/archivRet">数据档案检索</el-menu-item>
                  <el-menu-item index="/patrol">巡视点管理</el-menu-item>
                  <el-menu-item index="/deviceList">设备清单</el-menu-item>
                  <el-menu-item index="/deviceScrap">报废设备</el-menu-item>
                  <el-menu-item index="/deviceSort">设备分类</el-menu-item>
                  <el-menu-item index="/facilitySort">设施位置</el-menu-item>
                  <el-menu-item index="/cycleAchievements">个人绩效</el-menu-item>
                  <el-menu-item index="/depAchievements">部门绩效</el-menu-item>
                </el-submenu>
                
                <!-- 全域数据分析与报表系统 -->
                <el-submenu index="24">
                  <template slot="title">
                    <i class="iconfont icon-jilu"></i>
                    <span slot="title">数据分析</span>
                  </template>
                  <el-menu-item index="/chartConfig">数据模型</el-menu-item>
                  <el-menu-item index="/iotDataReport">数据报表</el-menu-item>
                  <el-menu-item index="/iotDataPosiCumuStat">数据统计</el-menu-item>
                  <el-menu-item index="/iotDataStatistics">数据分析</el-menu-item>
                </el-submenu>
                
                <!-- 风险预警告警系统 -->
                <el-submenu index="9">
                  <template slot="title">
                    <i class="iconfont icon-gaojingguanli"></i>
                    <span slot="title">告警管理</span>
                  </template>
                  <el-menu-item index="/alarmEvent">告警列表</el-menu-item>
                  <el-menu-item index="/alarmRules">告警规则</el-menu-item>
                  <el-menu-item index="/daily">日报列表</el-menu-item>
                  <el-menu-item index="/receiveNotice">消息通知</el-menu-item>
                  <el-menu-item index="/stayDownloadList">待下载列表</el-menu-item>
                </el-submenu>

                <!-- 虚拟机器人辅助工作系统 -->
                <el-submenu index="25">
                  <template slot="title">
                    <i class="iconfont icon-gaojingguanli"></i>
                    <span slot="title">智能辅助</span>
                  </template>
                </el-submenu>
                
                <!-- <el-submenu index="5">
                  <template slot="title">
                    <i class="iconfont icon-huizhiguiji"></i>
                    <span slot="title">轨迹管理</span>
                  </template>
                  <el-menu-item index="/onlineStatus">在线状态</el-menu-item>
                  <el-menu-item index="/staffTrack">员工轨迹</el-menu-item>
                  <el-menu-item index="/staffOdometer">员工里程表</el-menu-item>
                  <el-menu-item index="/staffTrackTable">员工轨迹表</el-menu-item>
                  <el-menu-item index="/attendance">出勤查询</el-menu-item>
                </el-submenu>
                <el-submenu index="6">
                  <template slot="title">
                    <i class="iconfont icon-dingdan"></i>
                    <span slot="title">外勤管理</span>
                  </template>
                  <el-menu-item index="/routineTask">外勤计划</el-menu-item>
                  <el-menu-item index="/fieldTask">外勤任务</el-menu-item>
                  <el-menu-item index="/directionalTask">指派任务</el-menu-item>
                </el-submenu>
                <el-submenu index="19">
                  <template slot="title">
                    <i class="iconfont icon-jilu"></i>
                    <span slot="title">外勤记录</span>
                  </template>
                  <el-menu-item index="/patrolReport">巡查上报</el-menu-item>
                </el-submenu>
                <el-submenu index="20">
                  <template slot="title">
                    <i class="iconfont icon-kefu"></i>
                    <span slot="title">客服中心</span>
                  </template>
                  <el-menu-item index="/faultReport">故障上报</el-menu-item>
                </el-submenu>
                <el-submenu index="10">
                  <template slot="title">
                    <i class="iconfont icon-zhishiku"></i>
                    <span slot="title">知识库</span>
                  </template>
                  <el-menu-item index="/knowledgeList">知识库列表</el-menu-item>
                  <el-menu-item index="/columnClassify">栏目分类</el-menu-item>
                </el-submenu>
                <el-submenu index="16">
                  <template slot="title">
                    <i class="iconfont icon-shuibiao"></i>
                    <span slot="title">水表档案</span>
                  </template>
                  <el-menu-item index="/userAccount">用户台账</el-menu-item>
                  <el-menu-item index="/waterAccount">水表台账</el-menu-item>
                  <el-menu-item index="/waterArchives">水表档案</el-menu-item>
                  <el-menu-item index="/waterMeter">水表属性</el-menu-item>
                </el-submenu>
                <el-submenu index="17">
                  <template slot="title">
                    <i class="iconfont icon-quyuguanli"></i>
                    <span slot="title">区域架构</span>
                  </template>
                  <el-menu-item index="/regionStructure">区域架构</el-menu-item>
                  <el-menu-item index="/regionManage">区域管理</el-menu-item>
                  <el-menu-item index="/bookManage">册本管理</el-menu-item>
                </el-submenu> -->

                <!-- 系统配置 -->
                <el-submenu index="15">
                  <template slot="title">
                    <i class="iconfont icon-xitongshezhi"></i>
                    <span slot="title">系统配置</span>
                  </template>
                  <el-menu-item index="/organization">组织架构</el-menu-item>
                  <el-menu-item index="/jobs">岗位管理</el-menu-item>
                  <el-menu-item index="/staff">员工管理</el-menu-item>
                  <el-menu-item index="/robot">机器人管理</el-menu-item>
                  <el-menu-item index="/apkManage">版本列表</el-menu-item>
                  <el-menu-item index="/menuManage">菜单管理</el-menu-item>
                  <el-menu-item index="/company">公司管理</el-menu-item>
                  <el-menu-item index="/password">修改密码</el-menu-item>
                  <el-menu-item index="/adminis">超级管理员</el-menu-item>
                </el-submenu>
              </template>
            </div>
            <!-- 非超级管理员 -->
            <div v-else>
              <div v-for="(item, index) in menusTree" :key="index">
                <div v-if="item.url=='/dashboard'">
                  <el-menu-item :index="item.url">
                    <i class="iconfont" :class="item.icon"></i>
                      <span slot="title">{{ item.title }}</span>
                  </el-menu-item>
                </div>
                <div v-else>
                  <el-submenu :index="item.url" v-if="item.childTree&&item.url!='/engineeringCamera'">
                    <template slot="title">
                      <i class="iconfont" :class="item.icon"></i>
                      <span slot="title">{{ item.title }}</span>
                    </template>
                    <el-menu-item :index="val.url" v-for="(val, key) in item.childTree" :key="key" @click="allRead(val.title)">
                      <span v-if="val.title=='告警列表'">
                        告警列表
                        <el-badge :value="alarmCount" :max="99" :style="alarmCount>99?'right: -25px;top: -10px;':'right: 0px;top: -10px;'" class="right-badge" v-show="alarmCount!=0"></el-badge>
                      </span>
                      <span v-else-if="val.title=='日报列表'">
                        日报列表
                        <el-badge :value="dailyReportCount" :max="99" :style="dailyReportCount>99?'right: -25px;top: -10px;':'right: 0px;top: -10px;'" class="right-badge" v-show="dailyReportCount!=0"></el-badge>
                      </span>
                      <span v-else-if="val.title=='消息通知'">
                        消息通知
                        <el-badge :value="noticeCount" :max="99" :style="noticeCount>99?'right: -25px;top: -10px;':'right: 0px;top: -10px;'" class="right-badge" v-show="noticeCount!=0"></el-badge>
                      </span>
                      <span v-else>{{ val.title }}</span>
                    </el-menu-item>
                  </el-submenu>
                </div>
              </div>
            </div>
          </el-menu>
        </el-aside>
        <el-main>
          <div>
            <router-view/>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <alarm-popup 
      :alarmPopupFlag="alarmPopupFlag" 
      :alarmDatas="alarmData" 
      ></alarm-popup>
    <!-- <alarm-popup
      :alarmPopupFlag="noticePopupFlag" 
      :alarmDatas="alarmData" 
      titleBackground="#0984e3"
      conBackground="#e5f1ff"
      tipBackground="#4f99e3"
      title="消息通知"
      subTitle="消息名称"
      content="消息内容"
      ></alarm-popup> -->
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { speakPlay,speakStop } from '@/js/Graph/speakPlay';
import AlarmPopup from '@/components/AlarmPopup';

export default {
  components: {
    AlarmPopup,
  },
  data() {
    return {
      name: '',
      isCollapse: false,
      active: '',
      roleId: 0,
      menusTree: [],
      noticeNum: 0,
      alarmCount: null,
      dailyReportCount: null,
      noticeCount: null,
      alarmPopupFlag:false,
      alarmData: {},
      // noticePopupFlag:false,
      tempWsData:[],
      notifyCom: null,
      notifyTime: null,
      notifyNum: 60
    }
  },
  watch:{
    '$route.path':function(newVal,oldVal){
      this.active = newVal
    }
  },
  created() {
    // 初始化websocket
    this.initWebsocket()
    this.handleList()
  },
  methods: {
    // 已读
    allRead(title) {
      if(title=='告警列表') {
      }else if(title=='日报列表') {
        this.reportHandleAll()
      }else if(title=='消息通知') {
        this.noticeReadAll()
      }
    },
    loginOut() {
      document.cookie = 'T='
      document.cookie = 'N='
      document.cookie = 'S='
      document.cookie = 'C='
      this.$router.push('/login')
      if(this.$globalWs.ws) {
        this.$globalWs.socketLeaveFlag = true
        this.$globalWs.ws.close()
      }
      speakStop()
      clearInterval(this.wsInterval)
      clearInterval(this.popupInterval)
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      if(this.active != key) {
        sessionStorage.removeItem('currentPage')
        sessionStorage.removeItem('currentSize')
      }
    },
    getUserName() {
      this.name = sessionStorage.getItem('name')
      this.roleId = sessionStorage.getItem('r')
    },
    messageNotice() {
      this.noticeNum = 0
      this.$router.push({ name: 'alarmEvent' })
    },
    // 获取消息通知个数
    loadNoticeData() {
      this.$ajax.post('latestCountQuery').then(res => {
        this.noticeNum = res.data.alarmCount+res.data.dailyReportCount+res.data.noticeCount
        this.alarmCount = res.data.alarmCount
        this.dailyReportCount = res.data.dailyReportCount
        this.noticeCount = res.data.noticeCount
        this.$children[0].$children[0].$forceUpdate()
      })
    },
    // 已读日报
    reportHandleAll() {
      if(this.dailyReportCount>0) {
        this.$ajax.post('reportHandleAll').then(res => {
          this.loadNoticeData()
        })
      }
    },
    // 已读消息
    noticeReadAll() {
      if(this.noticeCount>0) {
        this.$ajax.post('noticeReadAll').then(res => {
          this.loadNoticeData()
        })
      }
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        // text: 'https://op.dbit365.com/ota/wapi/v1/file/qrcode/download', // 需要转换为二维码的内容
        text: 'https://test.dbit365.com/ota/wapi/v1/file/qrcode/download', // 需要转换为二维码的内容
        width: 150,
        height: 150,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 初始化websocket
    initWebsocket() {
      if(typeof WebSocket==='undefined') {
        cosnole.log('浏览器不支持websocket')
        return
      }
      let companyId = sessionStorage.getItem('c')
      let staffId = sessionStorage.getItem('id')
      let url = 'wss://test.dbit365.com//webpush/ws?companyId='+companyId+'&staffId='+staffId
      // 打开websocket
      this.$globalWs.setWs(new WebSocket(url));
      // 建立连接
      this.$globalWs.ws.onopen = () => {
        console.log('%c[连接成功] Websocket connected','color: #10ac84')
        if(this.notifyCom) {
          this.notifyCom.close();
          window.clearInterval(this.notifyTime);
          this.notifyTime = null;
          document.querySelectorAll('body .el-notification').forEach(v=>{
            v.remove()
          })
          this.$notify.success({
            title: '提示',
            message: 'websocket重新连接成功',
            showClose: false
          });
        }
        // 心跳检测重置
        this.$globalWs.ws.send("ping");
        this.$globalWs.heartCheck.reset().start(this.$globalWs.ws);
      };
      // 客户端收到服务的返回的数据
      this.$globalWs.ws.onmessage = e => {
        if(e.data.indexOf('pong') > -1) {
          // 心跳回复——心跳检测重置
          // 收到心跳检测回复就说明连接正常
          console.log("收到心跳检测回复");
          // 心跳检测重置
          this.$globalWs.heartCheck.reset().start(this.$globalWs.ws);
        }else {
          // 普通推送——正常处理
          console.log("收到推送消息");
          let data = JSON.parse(e.data)
          this.tempWsData.push(data)
        }
      }
      // 发送错误时
      this.$globalWs.ws.onerror = e => {
        console.log('%c[连接出错] Websocket error','color: #feca57')
        console.log('%c[出错信息] Error info','color: #576574',e)
        // websocket重连
        this.notifyNum = 60
        this.socketReconnect();
      };
      // 关闭连接
      this.$globalWs.ws.onclose = e => {
        console.log('%c[连接关闭] Websocket closed','color: #ee5253')
        // 心跳检测重置
        this.$globalWs.heartCheck.reset();
        if (!this.$globalWs.socketLeaveFlag) {
          // 没有离开——重连
          // websocket重连
          this.notifyNum = 60
          this.socketReconnect();
        }
      }
    },
    // websocket重连
    socketReconnect() {
      if (this.$globalWs.socketReconnectLock) {
        return;
      }
      this.$globalWs.socketReconnectLock = true;
      this.$globalWs.socketReconnectTimer && clearTimeout(this.$globalWs.socketReconnectTimer);
      this.notifyTime = setInterval(() => {
        if (this.notifyNum > 1 && this.notifyNum <= 60) {
          this.notifyNum--;
          let dom = document.querySelector('#countDownNumber');
          dom.innerText = this.notifyNum;
        } else {
          this.notifyCom.close();
          window.clearInterval(this.notifyTime);
          this.notifyTime = null;
        }
      }, 1000)
      this.notifyCom = this.$notify({
          title: '警告',
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: `websocket重新连接倒计时:<span id="countDownNumber" style="color: #f00;margin: 0 10px;">${this.notifyNum}</span>秒`,
          duration: 0,
          showClose: false,
      });
      
      this.$globalWs.socketReconnectTimer = setTimeout(() => {
        console.log("WebSocket:重连中...");
        this.$globalWs.socketReconnectLock = false;
        // websocket启动
        this.initWebsocket();
      }, 60000);
    },
    // 通过缓存数组处理websocket卡顿
    handleList() {
      // let n = 'popup'
      // setInterval(()=>{
      //   if(n=='voice') {
      //     speakPlay('1号清水池液位超过5m')
      //   }
      //   if(n=='popup') {
      //     this.alarmData.title = '水位溢出'
      //     this.alarmData.content = '1#清水池液位超过5m'
      //     console.log(this.alarmPopupFlag)
      //     this.alarmPopupFlag = true
      //   }
      // },1000)
      // setInterval(()=>{
      //   if(n=='popup') {
      //     this.alarmPopupFlag = false
      //   }
      // },10000)
      // return
      this.wsInterval = setInterval(()=>{
        if(this.tempWsData.length==0) return
        if(this.tempWsData.length>10) {
          this.tempWsData = []
          return
        }
        let dmessage = JSON.parse(this.tempWsData[0].message)
        // 推送消息为告警数据
        if(this.tempWsData[0].messageType=='configurationAlarm') {
          dmessage.notificationModeCodes.forEach(n=>{
            if(n=='voice') {
              speakPlay(dmessage.content)
            }
            if(n=='popup') {
              this.alarmData.title = dmessage.title
              this.alarmData.content = dmessage.content
              this.alarmPopupFlag = true
            }
          })
        }
        this.tempWsData.shift()
      },100)
      // tpye=='popup' 每隔10秒隐藏弹窗
      this.popupInterval = setInterval(()=>{
        this.alarmPopupFlag = false
      },10000)
    },
  },
  mounted() {
    let active = location.hash.match(/\/[a-z, A-Z]*/)
    this.active = active[0]
    this.getUserName()
    this.menusTree = JSON.parse(sessionStorage.getItem('menus'))
    this.creatQrCode();

    if(sessionStorage.getItem('id')!='null') {
      this.loadNoticeData()
    }
  }
}
</script>

<style lang="less" scoped>
#home {
  .el-dropdown {
    .el-dropdown-link {
      font-size: 15px;
      color: #fff;
      transition: all .3s ease;
    }
    .el-dropdown-link:hover {
      color: #ccc;
    }
  }
  .el-container {
    height: 100%;
    background: #FAFAFA;
  }
  section {
    overflow-y: hidden;
  }
  .el-main {
    // margin: 14px;
    overflow-y: auto;
    height: 100%;
    padding: 14px;
    >div {
      overflow-y: auto;
      // padding: 10px;
      height: 100%;
      box-sizing: border-box;
      background: #fff;
      border-radius: 10px;
      >div {
        padding: 10px;
      }
    }
  }
  
  .el-header {
    height: 64px !important;
    line-height: 64px;
    overflow: hidden;
    background-color: #363c42;
    color: #fff;
    padding-left: 0;
    .left {
      float: left;
      font-size: 20px;
      line-height: 64px;
      vertical-align: middle;
      font-weight: bold;
      i {
        font-size: 20px;
        padding: 0 22px;
        line-height: 64px;
        cursor: pointer;
        &:hover {
          background: #283446;
        }
      }
    }
    .right {
      float: right;
      .qrcode-wrap {
        position: relative;
        margin-right: 30px;
        &:hover .qrcode {
          display: block;
        }
        .qrcode-name {
          font-size: 15px;
          transition: all .3s ease;
          cursor: pointer;
        }
        .qrcode{
          position: fixed;
          top: 75px;
          right: 230px;
          z-index: 10000;
          display: none;
          padding: 10px;
          background: #FFF;
          border: 1px solid #F2F2F2;
          border-radius: 5px;
          box-shadow: 0px 2px 12px rgb(214, 214, 214);
          &::before {
            content: '';
            position: absolute;
            top: -16px;
            left: 52px;
            width: 0px;
            height: 0px;
            border-top: 8px solid transparent;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #FFF;
          }
          img {
            width: 132px;
            height: 132px;
            background-color: #fff; //设置白色背景色
            padding: 6px; // 利用padding的特性，挤出白边
            box-sizing: border-box;
          }
        }
        .qrcode-name:hover {
          color: #ccc;
        }
      }
      
      .right-notice {
        font-size: 15px;
        margin-right: 30px;
        cursor: pointer;
        position: relative;
        transition: all .3s ease;
      }
      .right-badge {
        position: absolute;
        right: -25px;
        top: -30px;
      }
      .right-notice:hover {
        color: #ccc;
      }
      .right-help {
        font-size: 15px;
        margin-right: 20px;
        cursor: pointer;
        transition: all .3s ease;
      }
      .right-help:hover {
        color: #ccc;
      }
      /deep/.el-badge__content {
        border: 0;
      }
    }
  }
  .el-aside {
    width: auto !important;
    background-color: #262a2e;
    padding: 10px;
  }
  .iconfont {
    font-size: 17px;
    margin: 0 10px 0 2px;
  }
}
.el-menu-item {
  font-size: 13px;
  background-color: #262a2e !important;
}
/deep/.el-menu {
  background-color: #262a2e !important;
}
/deep/.el-submenu .el-menu-item {
  padding-left: 60px !important;
  min-width: 200px;
  border-radius: 5px;
}
/deep/.el-menu-item:hover {
  color: #409EFF !important;
  border-radius: 5px;
  background-color: #363d46 !important;
}
/deep/.el-menu-item.is-active {
  background-color: #363d46 !important;
  border-radius: 5px;
}
/deep/.el-submenu__title {
  font-size: 13px;
  background-color: #262a2e !important;
}
/deep/.el-submenu__title:hover {
  border-radius: 5px;
}
</style>

<style lang="less">
.el-menu {
  border-right: none !important;
}
.window {
  font-size: 14px;
  font-size: 14px;
  margin-right: 16px;
  cursor: pointer;
  i {
    padding-right: 4px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-dropdown-menu {
  z-index: 6000 !important;
}
.el-menu--collapse .el-submenu__title span {
  display: none;
}
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>